import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { TertiaryTitle } from "src/components/Typography"
import BaseIcon from "src/components/Base/BaseIcon"
import BaseButton from "src/components/Base/BaseButton"
import ResourcesHeader from "src/components/ResourcesHeader"
import ResourcesRegistrationPopup from "src/components/ResourcesRegistrationPopup"
import Tags from "src/components/Tags"
import Gallery from "src/components/Gallery"
import staticResources from "../../data/resources"
import {
  Navigation,
  NavigationList,
  NavigationListItem,
  NavigationListLink,
  NavigationListLinkTitle,
} from "src/templates/resource-category"
import BlogPostAuthor from "src/components/BlogPostAuthor"
import {
  Wrapper,
  Container,
  Sidebar,
  SearchForm,
  Main,
} from "src/templates/resource-category"
import { get } from "lodash"
import { slugify } from "src/utils/helpers"

export const pageQuery = graphql`
  query ($id: String) {
    allStrapiResource(filter: { strapiId: { eq: $id } }) {
      edges {
        node {
          Title
          full_title
          Description
          Preview {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 407, quality: 80, layout: FIXED)
              }
            }
          }
          content_type
          content_length
          country {
            name
          }
          file_size
          license_details
          number_of_files
          date_of_capture
          createdAt(formatString: "MMMM D, YYYY")
          keywords {
            keyword
          }
          Sharable_link_to_dropbox_folder_or_file
          author {
            Name
            description_to_be_shown_next_to_their_webinars
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 84, quality: 90, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  }
`

const ResourcesDetails = ({
  pageContext: { id, parent, allCategories },
  data,
}) => {
  const {
    Title: title,
    full_title: fullTitle,
    Description: description,
    Preview: preview,
    content_type: contentType,
    content_length: length,
    country,
    file_size: fileSize,
    license_details: source,
    number_of_files: numberOfFiles,
    date_of_capture: year,
    createdAt: dateAdded,
    keywords,
    Sharable_link_to_dropbox_folder_or_file: downloadUrl,
    author,
  } = data.allStrapiResource.edges[0].node

  const [registrationPopupVisible, setRegistrationPopupVisible] =
    useState(false)
  const [hasRatedPositive, setRatedPositive] = useState(false)
  const [hasRatedNegative, setRatedNegative] = useState(false)
  const [ratingCount, setRatingCount] = useState(0)

  const rootPath = "/resources"

  const iconColor = parent[0] && parent[0].color
  const iconName = (parent[0] && parent[0].icon) || "camera"

  const breadcrumbs = [
    {
      name: "Resource library",
      path: `${rootPath}#categories`,
    },
    ...parent.map(({ name, slug }, index) => {
      const path = `${rootPath}/${
        index > 0
          ? (index > 1
              ? `${parent[0].slug}/${parent[1].slug}`
              : parent[0].slug) + "/"
          : ""
      }${slug}`
      return {
        name,
        path,
      }
    }),
  ]

  const extendedDescription = [
    "Webinars",
    "Articles",
    "Talks and lectures",
  ].includes(parent[0].name)
  const typeDict = {
    Webinars: "Webinar",
    Articles: "Article",
    "Talks and lectures": "Lecture",
  }
  const authorTypeDict = {
    Webinars: "speaker",
    Articles: "author",
    "Talks and lectures": "speaker",
  }
  let displayedType =
    typeDict[[parent[0].name]] ||
    (contentType === "Picture"
      ? "Photo"
      : contentType === "Video"
      ? "Video"
      : "")

  const textPreview = null

  const accessMethod = downloadUrl.includes("notion")
    ? parent[0].name === "Webinars"
      ? "Watch"
      : "Read"
    : "Download"

  const downloadButtonLabel = `${accessMethod} ${
    numberOfFiles && numberOfFiles > 1 ? numberOfFiles : ""
  } ${displayedType.toLowerCase()}${
    numberOfFiles && numberOfFiles > 1 ? "s" : ""
  }`

  const previewUrl =
    (downloadUrl.includes("dropbox.com") &&
      fileSize &&
      getFileSize(fileSize) <= 536870912 /* or 512 MB */ &&
      downloadUrl.replace("dl=1", "dl=0")) ||
    null

  const previewButtonLabel = "Preview"

  const showVideoPreview =
    ["Webinars", "Talks and lectures"].includes(parent[0].name) &&
    downloadUrl &&
    downloadUrl.includes(".mp4")

  const videoPreviewUrl = downloadUrl.split(".mp4")[0] + ".mp4?raw=1"
  const videoPosterUrl =
    preview[0]?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback
      ?.src

  const currentNavigationItem = (
    <NavigationListItem key={-1}>
      <NavigationListLink
        className="-active"
        to={breadcrumbs.slice(-1)[0].path}
      >
        <NavigationListLinkTitle>
          {parent.slice(-1)[0].name}
        </NavigationListLinkTitle>
      </NavigationListLink>
    </NavigationListItem>
  )

  const navigationTree = (
    <NavigationList>
      {staticResources.map((category, index) => {
        if (parent.length > 0 && parent[0].name === category.name) {
          return (
            <NavigationListItem key={index}>
              <NavigationListLink
                className="-active"
                to={`${rootPath}/${parent[0].slug}`}
              >
                <NavigationListLinkTitle>
                  {parent[0].name}
                </NavigationListLinkTitle>
              </NavigationListLink>
              <NavigationList>
                {allCategories
                  .filter(
                    (category) =>
                      category.parent_category &&
                      category.parent_category.id === parent[0].id &&
                      category.resources.length > 0
                  )
                  .map((category, index) => {
                    return category.name === parent.slice(-1)[0].name ? (
                      currentNavigationItem
                    ) : (
                      <NavigationListItem key={index}>
                        <NavigationListLink
                          to={`${rootPath}/${parent[0].slug}/${slugify(
                            category.name
                          )}`}
                        >
                          <NavigationListLinkTitle>
                            {category.name}
                          </NavigationListLinkTitle>
                        </NavigationListLink>
                        {parent.length > 1 &&
                        category.name === parent[1].name ? (
                          <NavigationList>
                            {allCategories
                              .filter(
                                (category) =>
                                  category.parent_category &&
                                  category.parent_category.id ===
                                    parent[1].id &&
                                  category.resources.length > 0
                              )
                              .map((category, index) => {
                                return category.name ===
                                  parent.slice(-1)[0].name ? (
                                  currentNavigationItem
                                ) : (
                                  <NavigationListItem key={index}>
                                    <NavigationListLink
                                      to={`${rootPath}/${parent[0].slug}/${
                                        parent[1].slug
                                      }/${slugify(category.name)}`}
                                    >
                                      <NavigationListLinkTitle>
                                        {category.name}
                                      </NavigationListLinkTitle>
                                    </NavigationListLink>
                                    {parent.length > 1 &&
                                    category.name === parent[1].name ? (
                                      <NavigationList>
                                        {currentNavigationItem}
                                      </NavigationList>
                                    ) : (
                                      ""
                                    )}
                                  </NavigationListItem>
                                )
                              })}
                          </NavigationList>
                        ) : (
                          ""
                        )}
                      </NavigationListItem>
                    )
                  })}
              </NavigationList>
            </NavigationListItem>
          )
        } else {
          if (category.name === parent.slice(-1)[0].name) {
            return currentNavigationItem
          } else {
            return (
              <NavigationListItem key={index}>
                <NavigationListLink to={`${rootPath}/${category.slug}`}>
                  <NavigationListLinkTitle>
                    {category.name}
                  </NavigationListLinkTitle>
                </NavigationListLink>
                {parent[0] && parent[0].name === category.name && (
                  <NavigationList>{currentNavigationItem}</NavigationList>
                )}
              </NavigationListItem>
            )
          }
        }
      })}
    </NavigationList>
  )

  function getFileSize(size) {
    // returns result in bytes
    return size
      .replace(",", ".")
      .replace(
        /(\d+)+(\.(\d+))?\s?(k|m|g|t)?b?/i,
        (value, p1, p2, p3, p4) =>
          parseFloat(p1 + (p2 || "")) *
          ({ K: 1 << 10, M: 1 << 20, G: 1 << 30, T: 1 << 40 }[p4] || 1)
      )
  }

  function getUser() {
    return (
      typeof localStorage !== "undefined" &&
      JSON.parse(localStorage.getItem("resourceLibraryUser"))
    )
  }

  function handleDownload(e) {
    if (getUser()) {
      trackDownload()
    } else {
      e.preventDefault()
      setRegistrationPopupVisible(true)
    }
  }

  function trackDownload() {
    const user = getUser()
    if ([typeof fetch, typeof user].includes("undefined")) {
      alert(
        "Sorry, your browser doesn’t support this feature. Please upgrade your browser."
      )
      return
    }
    if (user.id) {
      fetch("https://api.animainternational.org/download-histories", {
        method: "POST",
        body: JSON.stringify({
          user_cookie: user.id,
          resource: id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            return Promise.reject({
              status: response.status,
              statusText: response.statusText,
            })
          }
        })
        .then((response) => {
          // console.log(response)
        })
        .catch((error) => console.log("Error:", error.statusText))
    }
  }

  function handleRating(isPositive) {
    const user = getUser()

    let postPositive = isPositive

    if (isPositive && hasRatedPositive) {
      postPositive = false
    }

    if (!isPositive && hasRatedNegative) {
      postPositive = true
    }

    fetch("https://api.animainternational.org/ratings", {
      method: "POST",
      body: JSON.stringify({
        resource_library_resource: id,
        positive: postPositive,
        user: user?.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isPositive) {
            setRatedPositive(!hasRatedPositive)
            setRatedNegative(false)
            setRatingCount(hasRatedPositive ? ratingCount - 1 : ratingCount + 1)
            if (typeof localStorage !== "undefined") {
              if (hasRatedPositive) {
                localStorage.removeItem(`hasRatedPositive_${id}`)
              } else {
                localStorage.setItem(`hasRatedPositive_${id}`, 1)
                localStorage.removeItem(`hasRatedNegative_${id}`)
              }
            }
          } else {
            setRatedNegative(!hasRatedNegative)
            setRatedPositive(false)
            setRatingCount(hasRatedNegative ? ratingCount + 1 : ratingCount - 1)
            if (typeof localStorage !== "undefined") {
              if (hasRatedNegative) {
                localStorage.removeItem(`hasRatedNegative_${id}`)
              } else {
                localStorage.setItem(`hasRatedNegative_${id}`, 1)
                localStorage.removeItem(`hasRatedPositive_${id}`)
              }
            }
          }
        } else {
          return Promise.reject({
            status: response.status,
            statusText: response.statusText,
          })
        }
      })
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => console.log("Error:", error.statusText))
  }

  useEffect(() => {
    const activeLinks = document.querySelectorAll(
      "a[class^='resource-category__NavigationListLink'].-active"
    )
    if (activeLinks.length > 1) {
      activeLinks[0].classList.remove("-active")
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem(`hasRatedPositive_${id}`)) {
      setRatedPositive(true)
    }
    if (localStorage.getItem(`hasRatedNegative_${id}`)) {
      setRatedNegative(true)
    }

    const url = `https://api.animainternational.org/ratings/count/?resource_library_resource=${id}`
    fetch(`${url}&positive=true`)
      .then((response) => response.json())
      .then((dataPositive) => {
        fetch(`${url}&positive=false`)
          .then((response) => response.json())
          .then((dataNegative) => {
            setRatingCount(Math.max(dataPositive - dataNegative))
          })
      })
  }, [id])

  /* eslint-disable jsx-a11y/media-has-caption */

  return (
    <Wrapper>
      <Seo title={title} />
      <Container wide>
        <Sidebar>
          <SearchForm />
          <Navigation>{navigationTree}</Navigation>
          <Details>
            {contentType && (
              <DetailsRow>
                <DetailsTitle>Type</DetailsTitle>
                <DetailsContent>
                  <TypeIcon name={contentType.toLowerCase()} />
                  {contentType === "Picture" ? "Photos" : contentType}
                </DetailsContent>
              </DetailsRow>
            )}
            {country && (
              <DetailsRow>
                <DetailsTitle>Country</DetailsTitle>
                <DetailsContent>{country.name}</DetailsContent>
              </DetailsRow>
            )}
            {year && (
              <DetailsRow>
                <DetailsTitle>Year</DetailsTitle>
                <DetailsContent>{year ? year.substring(0, 4) : ""}</DetailsContent>
              </DetailsRow>
            )}
            {fileSize && (
              <DetailsRow>
                <DetailsTitle>File size</DetailsTitle>
                <DetailsContent>{fileSize.replace(",", ".")}</DetailsContent>
              </DetailsRow>
            )}
            {length && (
              <DetailsRow>
                <DetailsTitle>Length</DetailsTitle>
                <DetailsContent>{length}</DetailsContent>
              </DetailsRow>
            )}
            {keywords.length > 0 && (
              <DetailsRow>
                <DetailsTitle>Keywords</DetailsTitle>
                <DetailsContent>
                  <StyledTags
                    items={keywords.map((k) => k.keyword.toLowerCase())}
                  />
                </DetailsContent>
              </DetailsRow>
            )}
            <DetailsRow>
              <DetailsTitle>Added on</DetailsTitle>
              <DetailsContent>{dateAdded}</DetailsContent>
            </DetailsRow>
            {source && (
              <DetailsRow>
                <DetailsTitle>Source</DetailsTitle>
                <DetailsContent>{source}</DetailsContent>
              </DetailsRow>
            )}
          </Details>
        </Sidebar>
        <Main>
          <ResourcesHeader
            title={fullTitle || title}
            description={!extendedDescription && description}
            breadcrumbs={breadcrumbs}
            icon={{
              color: iconColor,
              name: iconName,
            }}
          />
          <Content>
            {showVideoPreview ? (
              <VideoPreview>
                <video controls poster={videoPosterUrl}>
                  <source src={videoPreviewUrl} />
                </video>
              </VideoPreview>
            ) : (
              !textPreview &&
              preview.length > 0 && (
                <StyledGallery
                  imagesSharp={
                    preview.length > 0 &&
                    preview.map((p) => get(p, "localFile.childImageSharp"))
                  }
                />
              )
            )}
            {extendedDescription && (
              <ExtendedDescription>{description}</ExtendedDescription>
            )}
            {textPreview && (
              <TextPreview>
                <TextPreviewTitle>Preview</TextPreviewTitle>
                <TextPreviewContent>{textPreview}</TextPreviewContent>
              </TextPreview>
            )}
            {downloadUrl && (
              <Actions>
                <DownloadButton
                  label={downloadButtonLabel}
                  to={getUser() && downloadUrl}
                  onClick={handleDownload}
                />
                {previewUrl && (
                  <PreviewButton
                    label={previewButtonLabel}
                    variant="outline"
                    to={previewUrl}
                    onClick={handleDownload}
                  />
                )}
                <Rating>
                  <RatingButton
                    isActive={hasRatedPositive}
                    onClick={() => handleRating(true)}
                  >
                    <RatingButtonIcon name="thumbs-up" source="feather" />
                    {ratingCount > 0 && (
                      <RatingButtonCount>{ratingCount}</RatingButtonCount>
                    )}
                    <RatingTooltip>It’s useful</RatingTooltip>
                  </RatingButton>
                  <RatingButton
                    isActive={hasRatedNegative}
                    onClick={() => handleRating(false)}
                  >
                    <RatingButtonIcon name="thumbs-down" source="feather" />
                    <RatingTooltip>It’s not useful</RatingTooltip>
                  </RatingButton>
                </Rating>
              </Actions>
            )}
            {author && author.Name !== "Anima International" && (
              <AuthorSection>
                <AuthorTitle>
                  About the {authorTypeDict[parent[0].name]}
                </AuthorTitle>
                <Author
                  name={author.Name}
                  bio={author.description_to_be_shown_next_to_their_webinars}
                  imageSharp={get(author, "picture.localFile.childImageSharp")}
                  path={`/resources/authors/${slugify(author.Name)}`}
                />
              </AuthorSection>
            )}
          </Content>
        </Main>
      </Container>
      <ResourcesRegistrationPopup
        resourceTitle={fullTitle || title}
        downloadButtonLabel={downloadButtonLabel}
        downloadUrl={downloadUrl}
        previewButtonLabel={previewButtonLabel}
        previewUrl={previewUrl}
        visible={registrationPopupVisible}
        onDownload={trackDownload}
        onClose={() => {
          setRegistrationPopupVisible(false)
        }}
      />
    </Wrapper>
  )
}

const Details = styled.div`
  position: relative;
  z-index: 1;
`

const DetailsRow = styled.div`
  margin-bottom: 30px;
`

const DetailsTitle = styled.h3`
  margin-bottom: 6px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  // color: #8c9d9d;
  color: ${(p) => p.theme.color.textLight};
`

const DetailsContent = styled.div`
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

const TypeIcon = styled(BaseIcon)`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  position: relative;
  top: -1px;
  color: #a7bbbb;
`

const StyledTags = styled(Tags)`
  margin-top: 13px;
`

const ExtendedDescription = styled.p`
  max-width: 724px;
  margin-bottom: 50px;
  font-size: ${(p) => p.theme.baseFontSize.phone};
  white-space: pre-line;
  color: ${(p) => p.theme.color.text};

  &:nth-child(n + 2) {
    margin-top: 85px;
  }

  ${media.tablet} {
    font-size: ${(p) => p.theme.baseFontSize.desktop};
  }
`

const Content = styled.div`
  ${media.tablet} {
    padding-right: ${(p) => p.theme.sidePadding.tablet}px;
  }

  ${media.desktop} {
    max-width: 774px;
    padding-right: ${(p) => p.theme.sidePadding.desktop}px;
  }
`

const VideoPreview = styled.div`
  margin-bottom: 50px;
  box-shadow: 0 20px 40px rgba(0, 11, 33, 0.08);

  video {
    display: block;
    width: 100%;
  }
`

const StyledGallery = styled(Gallery)`
  margin-left: -${(p) => p.theme.sidePadding.phone}px;
  margin-right: -${(p) => p.theme.sidePadding.phone}px;
  margin-bottom: 50px;

  &,
  .gatsby-image-wrapper {
    max-width: calc(100vw);
  }

  .gatsby-image-wrapper {
    min-height: 0;
    max-height: calc((100vw) * 0.5625);
  }

  ${media.tablet} {
    margin-left: 0;
    margin-right: 0;

    &,
    .gatsby-image-wrapper {
      max-width: calc(100vw - 320px);
    }

    .gatsby-image-wrapper {
      max-height: calc((100vw - 320px) * 0.5625);
    }
  }

  ${media.desktop} {
    &,
    .gatsby-image-wrapper {
      max-width: calc(100vw - 440px);
    }

    .gatsby-image-wrapper {
      max-height: calc((100vw - 440px) * 0.5625);
    }
  }

  ${media.desktopMedium} {
    &,
    .gatsby-image-wrapper {
      max-width: 100%;
    }

    .gatsby-image-wrapper {
      max-height: none;
    }
  }
`

const TextPreview = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;
`

const TextPreviewTitle = styled(TertiaryTitle)`
  margin-bottom: 40px;
`

const TextPreviewContent = styled.p`
  max-height: 400px;
  padding: 30px;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 20px 40px rgba(0, 11, 33, 0.08);
  font-size: 15px;
  line-height: 1.5;
  color: ${(p) => p.theme.color.textLight};
  white-space: pre-line;
  font-family: ${(p) => p.theme.font.mono};
  user-select: none;
  pointer-events: none;

  &::before {
    content: "";
    height: 70%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
`

const Actions = styled.div`
  ${media.tablet} {
    display: flex;
    align-items: center;
  }
`

const DownloadButton = styled(BaseButton)`
  margin-right: 10px;
`

const PreviewButton = styled(BaseButton)`
  margin-top: 10px;

  ${media.tablet} {
    margin-top: 0;
  }
`

const Rating = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: auto;

  ${media.tablet} {
    margin-top: 0;
  }
`

const RatingButton = styled.button`
  display: flex;
  align-items: center;
  min-width: 50px;
  height: 50px;
  padding: 0 13px;
  position: relative;
  color: #8c9d9d;
  transition: color 0.15s;

  &:hover {
    color: ${(p) => p.theme.color.primary};
  }

  ${(p) =>
    p.isActive &&
    css`
      background: #f3f6f6;
      border-radius: 3px;
      color: ${(p) => p.theme.color.primary};

      svg {
        opacity: 1;
      }
    `}
`

const RatingButtonIcon = styled(BaseIcon)`
  opacity: 0.7;
  position: relative;
  transition: opacity 0.15s;

  ${RatingButton}:nth-child(1) & {
    top: -1px;
  }

  ${RatingButton}:nth-child(2) & {
    top: 1px;
  }

  ${RatingButton}:hover & {
    opacity: 1;
  }
`

const RatingButtonCount = styled.span`
  margin-left: 8px;
  font-weight: 500;
`

const Tooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  padding: 7px 20px;
  position: absolute;
  top: 50px;
  left: 20px;
  z-index: 1;
  transform: translateX(-50%) translateY(-5px);
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  font-weight: ${(p) => p.theme.basefontWeight};
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
  pointer-events: none;
  transition: 0.15s;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: calc(50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.9);
  }
`

const RatingTooltip = styled(Tooltip)`
  ${media.pointer} {
    ${RatingButton}:hover & {
      visibility: visible;
      opacity: 1;
      transform: translateX(-50%);
    }
  }
`

const AuthorSection = styled.div`
  margin-top: 80px;

  ${media.tablet} {
    margin-top: 120px;
  }
`

const AuthorTitle = styled(TertiaryTitle)`
  margin-top: 0;
  margin-bottom: 50px;

  ${media.tablet} {
    /* margin-top: 120px; */
  }
`

const Author = styled(BlogPostAuthor)`
  margin-top: 0;

  ${media.tablet} {
    /* margin-top: 140px; */
  }
`

export default ResourcesDetails
